import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
// import getVideoId from 'get-video-id'
import ytIcon from '../images/youtube-enlarge-icon.png'

const Videos = ({ data }) => {
  const Vids = data.allSanityVideo.edges

  console.log('Videos', Vids)
  return (
    <Layout>
      <SEO title="Videos" description="Sandy Scott Consulting Group" />
      <div className="about-us-wrapper">
        {' '}
        <section
          className="about-hero-wrapper"
          style={{ padding: '200px 0', marginTop: '5rem' }}
        >
          <article>
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-md-9 text-lg-center ml-md-0 hero-text-wrapper mx-lg-auto">
                  <h1 className="ml-4 black-brightness">Videos</h1>
                </div>
              </div>
            </div>
          </article>
        </section>
        <section
          className="videos-page"
          style={{ backgroundColor: '#fff', padding: '100px 0 150px' }}
        >
          <div className="container">
            <p className="mb-5">
              To view videos full screen, click on the{' '}
              <img src={ytIcon} alt="icon" /> icon. Press esc to go back to grid
              view.
            </p>
            <div className="row">
              {Vids.map((item, i) => (
                <div className="col-md-4" key={i}>
                  <div>
                    <iframe
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${item.node.videourl}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      sameorigin="true"
                      title={item.node.title}
                      className="mb-0"
                      style={{ borderRadius: '8px' }}
                    />
                    <Link to={`/${item.node.slug.current}`}>
                      <h5 className="mb-5">{item.node.title}</h5>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default Videos

export const VideosPage = graphql`
  query VideosPage {
    allSanityVideo {
      edges {
        node {
          id
          videourl
          title
          _rawDescription
          slug {
            current
          }
        }
      }
    }
  }
`
